#acces-challenges {
	width: 20rem;
	height: 20rem;

    position: absolute;
    top: 69%;
    right: 8.7%;
    z-index: 99;

    transform-origin: 0 0;
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);

    transition: opacity .25s ease;
}
#acces-challenges:hover { opacity: 0.6; }
#acces-challenges .mobile { display: none; }

#cas > .wrapper {
	margin: auto !important;
	position: relative;
}

#acces-challenges object {
    pointer-events: none;
}

@media only screen and (max-width: 1450px) {
	#acces-challenges {
		top: 59%;
		right: 19.7%;

		width: 159px;	
		height: 159px;
	}
}

@media only screen and (max-width: 1024px) {
	#acces-challenges {
		top: 64.5%;
		right: 40.1%;
	}
}

@media only screen and (max-width: 768px) {
	#acces-challenges {
		background: #1f1f1f;
		display: block;
		margin: auto;

		width: auto;
		height: auto;

		position: relative;
		top: 0;
		right: 0;

		transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
        
        text-align: center;
	}

	#acces-challenges object {
		margin: auto;
		width: 159px;
	}
	#acces-challenges .desktop { display: none; }
	#acces-challenges .mobile { display: inline-block; }
}
